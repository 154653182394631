<template>
  <div>
    <div class="panel-block">
      <div v-if="exibirTabela()">
        <template>
          <section v-if="activeTab == 'PENDENTE'">
            <div class="date-picker-container">
              <b-datepicker
                editable
                placeholder="Alterar Vencimento:"
                icon="calendar-today"
                v-mascara:data
                :min-date="menorData"
                v-model="novoVencimento"
                onkeypress="return event.charCode >= 48 && event.charCode <= 57"
              >
              </b-datepicker>
              <b-checkbox
                v-model="filtrar"
                @input.native="event => filterDates(event.target.checked)"
                class="checkbox-margin"
              ></b-checkbox>
              <button class="button is-primary" @click.prevent.stop="callUpdateDueDate">
                Alterar Vencimento
              </button>
              <button class="button is-primary" @click.prevent.stop="resetVencimentos">
                Resetar
              </button>
              <button class="button is-primary" @click.prevent.stop="callSyncBills">
                Sincronizar Boletos
              </button>
            </div>
          </section>
          <section v-if="activeTab == 'NOTA_EMITIDA'">
            <div class="date-picker-container">
              <button class="button is-primary" @click.prevent.stop="callSyncBills">
                Sincronizar Boletos
              </button>
            </div>
            <b-loading is-full-page="true" v-model="isSyncing">
              <b-icon pack="fas" icon="sync-alt" size="is-large" custom-class="fa-spin"> </b-icon>
            </b-loading>
          </section>
          <section v-if="activeTab == 'NOTA_EMITIDA'">
            <div class="date-picker-container">
              <button class="button is-primary" @click.prevent.stop="callSyncBills">
                Sincronizar Boletos
              </button>
            </div>
            <b-loading is-full-page="true" v-model="isSyncing">
              <b-icon pack="fas" icon="sync-alt" size="is-large" custom-class="fa-spin"> </b-icon>
            </b-loading>
          </section>
          <section v-if="activeTab == 'BOLETO_EMITIDO'">
            <div class="date-picker-container">
              <button class="button is-primary" @click.prevent.stop="callSendEmails">
                Enviar Cobranças
              </button>
            </div>
            <b-loading is-full-page="true" v-model="isSyncing">
              <b-icon pack="fas" icon="sync-alt" size="is-large" custom-class="fa-spin"> </b-icon>
            </b-loading>
          </section>
        </template>
        <b-table
          backend-pagination
          class="table is-fullwidth"
          default-sort-direction="ASC"
          :data="filteredInvoicesList"
          :mobile-card="false"
        >
          <b-table-column sortable field="ofertaCompraId" label="OC" v-slot="props">
            <a
              :href="`${window.location.origin}/pregoes/${props.row.ofertaCompraId}`"
              target="_blank"
            >
              {{ props.row.ofertaCompraId }}
            </a>
          </b-table-column>

          <b-table-column sortable field="numeroOC" label="Pregão" v-slot="props">
            <a
              :href="`${window.location.origin}/pregoes/${props.row.ofertaCompraId}`"
              target="_blank"
            >
              {{ props.row.numeroOC }}
            </a>
          </b-table-column>

          <b-table-column sortable field="comprador" label="Órgão" width="300" v-slot="props">
            {{ props.row.comprador }}
          </b-table-column>

          <b-table-column sortable field="fornecedor" label="Fornecedor" width="300" v-slot="props">
            {{ props.row.fornecedor }} ({{ props.row.ofertaCompraFornecedorId }})
          </b-table-column>

          <b-table-column sortable field="tipoPregao" label="Tipo" v-slot="props">
            {{ props.row.tipoPregao }}
          </b-table-column>

          <b-table-column
            sortable
            centered
            field="dataVencimento"
            label="Vencimento"
            v-slot="props"
          >
            <span :class="getClassByDate(props.row.dataVencimento)">
              {{ formatarDataSemHorario(props.row.dataVencimento) }}
            </span>
          </b-table-column>

          <b-table-column
            v-if="activeTab !== 'FATURA_EMITIDA'"
            numeric
            sortable
            field="bruto"
            label="Vr Bruto"
            v-slot="props"
          >
            {{ formatarValor(props.row.valorBruto, 2) }}
          </b-table-column>

          <b-table-column
            v-if="activeTab !== 'FATURA_EMITIDA'"
            numeric
            sortable
            field="teto"
            label="Vr Faturar"
            v-slot="props"
          >
            {{ formatarValor(props.row.valorFaturar, 2) }}
          </b-table-column>

          <b-table-column
            v-if="activeTab === 'FATURA_EMITIDA'"
            numeric
            sortable
            field="teto"
            label="Faturado"
            v-slot="props"
          >
            {{ formatarValor(props.row.valorFaturar, 2) }}
          </b-table-column>

          <b-table-column
            v-if="activeTab === 'FATURA_EMITIDA'"
            numeric
            sortable
            field="teto"
            label="Impostos"
            v-slot="props"
          >
            {{ formatarValor(props.row.valorImpostos, 2) }}
          </b-table-column>

          <b-table-column
            v-if="activeTab === 'PENDENTE'"
            centered
            sortable
            field="status"
            label="Check"
            width="60"
            v-slot="props"
          >
            <span :style="{ width: '60px' }" :class="badgeStatus(props.row.consistencia)">
              {{ props.row.consistencia }}
            </span>
          </b-table-column>

          <b-table-column class="options-column" centered label="Opções" v-slot="props">
            <button
              v-if="activeTab == 'PENDENTE'"
              class="button is-primary is-small is-outlined offers-button"
              @click.prevent.stop="callCreateInvoice(props.row)"
              :disabled="props.row.consistencia === 'ERRO'"
            >
              Faturar
            </button>
            <button
              v-if="activeTab == 'FATURA_EMITIDA'"
              class="button is-primary is-small is-outlined offers-button"
              @click.prevent.stop="callIssueInvoice(props.row)"
            >
              Emitir Nota
            </button>
          </b-table-column>
        </b-table>
      </div>
      <div class="container has-text-centered" v-if="!exibirTabela()">
        <p>Nenhum registro a ser exibido.</p>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import { funcoes } from '@/utils/enumerados';
import { formatarValor, formatarValorSemPrefixo, formatarDataSemHorario } from '@/utils/format';

export default {
  name: 'InvoiceTable',
  props: ['activeTab'],

  data() {
    const menorData = new Date();
    const novoVencimento = new Date();
    const filtrar = false;
    return {
      funcoes,
      formatarValor,
      formatarValorSemPrefixo,
      formatarDataSemHorario,
      menorData,
      novoVencimento,
      filtrar,
      filteredInvoicesList: [],
      isSyncing: false,
      synced: false,
    };
  },
  async created() {
    await this.fetchInvoices({ status: this.activeTab });
    this.filteredInvoicesList = this.filteredInvoices(this.filtrar ? this.novoVencimento : null);
  },
  computed: {
    ...mapGetters('invoices', ['filteredInvoices']),
    ...mapState('invoices', ['invoices']),
  },
  methods: {
    ...mapActions('invoices', [
      'createInvoices',
      'issueInvoice',
      'updateDueDate',
      'fetchInvoices',
      'syncBills',
      'sendEmails',
    ]),
    exibirTabela() {
      return this.invoices.length > 0;
    },
    filterDates(checked) {
      this.filtrar = checked;
      this.filteredInvoicesList = this.filteredInvoices(this.filtrar ? this.novoVencimento : null);
    },
    getClassByDate(data) {
      const hoje = new Date();
      const dataVencimento = new Date(data);
      hoje.setHours(0, 0, 0, 0); // Zera a hora para comparar apenas as datas

      if (dataVencimento < hoje) {
        return 'cor-vermelho';
      }
      if (dataVencimento.toDateString() === hoje.toDateString()) {
        return 'cor-amarelo';
      }
      return 'cor-verde';
    },
    callUpdateDueDate() {
      this.updateDueDate(this.novoVencimento);
      this.filteredInvoicesList = this.filteredInvoices(this.filtrar ? this.novoVencimento : null);
    },
    resetVencimentos() {
      this.fetchInvoices({ status: this.activeTab });
    },
    badgeStatus(consistencia) {
      return {
        tag: true,
        'is-success': consistencia === 'OK',
        'is-danger': consistencia === 'ERRO',
      };
    },
    callCreateInvoice(row) {
      const data = [
        {
          ofertaCompraFornecedorId: row.ofertaCompraFornecedorId,
          dataVencimento: row.dataVencimento,
        },
      ];

      this.createInvoices(data);
    },
    callIssueInvoice(row) {
      const data = [
        {
          ofertaCompraFornecedorId: row.ofertaCompraFornecedorId,
          faturaId: row.faturaId,
        },
      ];

      this.issueInvoice(data);
    },
    async callSendEmails() {
      const invoices = this.filteredInvoicesList.map(invoice => invoice.faturaId);
      await this.sendEmails(invoices);
    },
    async callSyncBills() {
      this.isSyncing = true;
      await this.syncBills();
      this.isSyncing = false;
    },
  },
};
</script>

<style scoped>
.right-aligned-field {
  text-align: right;
}
.date-picker-container {
  display: flex;
  align-items: center;
  justify-content: flex-end; /* Alinha os elementos à direita */
}

.date-picker-container span {
  margin-right: 10px;
}

.date-picker-container .button {
  margin-left: 10px;
}

.checkbox-margin {
  margin-left: 10px; /* Adicione o valor de margem desejado */
}
</style>
